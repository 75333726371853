import React from "react";

import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Container } from "./styles";

function Logo({ scroll, siteTitle }) {
  const { logo, logo2 } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "common/logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logo2: file(relativePath: { eq: "common/logo2_branco.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <Container scroll={scroll}>
      <Link to="/">
        {scroll > 100 ? (
          <Img fluid={logo2.childImageSharp.fluid} alt={siteTitle} />
        ) : (
          <Img fluid={logo.childImageSharp.fluid} alt={siteTitle} />
        )}
      </Link>
    </Container>
  );
}

export default Logo;
