import React, { useState, useEffect } from "react";

import { Link } from "gatsby";

import Logo from "./Logo";
import Nav from "./Nav";
import {
  Container,
  Navbar,
  NavItems,
  NavSocial,
  InstagramIcon,
  NewsletterIcon,
  FacebookIcon,
  NavbarContainer,
} from "./styles";
import Toggle from "./Toggle";

function Header({ siteTitle }) {
  const [scrollPosition, setSrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container scroll={scrollPosition}>
      <NavbarContainer>
        <Logo siteTitle={siteTitle} scroll={scrollPosition} />
        <Navbar>
          <NavItems scroll={scrollPosition}>
            <Nav scroll={scrollPosition} />
          </NavItems>
          <NavSocial scroll={scrollPosition}>
            <ul>
              <li>
                <Link
                  to="https://www.instagram.com/helenacampiglia"
                  target="_blanc"
                  alt="Instagram">
                  <InstagramIcon />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.facebook.com/drahelenacampiglia"
                  target="_blanc"
                  alt="Facebook">
                  <FacebookIcon />
                </Link>
              </li>
              <li>
                <Link to="/newsletter" alt="Newsletter">
                  <NewsletterIcon />
                </Link>
              </li>
            </ul>
          </NavSocial>
        </Navbar>
        <Toggle />
      </NavbarContainer>
    </Container>
  );
}

export default Header;
