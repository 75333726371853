import styled from "styled-components";
import { Instagram, Whatsapp } from "styled-icons/fa-brands";
import { Phone, MapPin } from "styled-icons/fa-solid";

import Img from "gatsby-image";

export const Logo = styled(Img)``;
export const Partner = styled(Img)``;
export const WhatsappIcon = styled(Whatsapp)``;
export const InstagramIcon = styled(Instagram)``;
export const PhoneIcon = styled(Phone)``;
export const MapPinIcon = styled(MapPin)``;
export const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.bgHeaderDark};
  width: 100%;
  margin: auto;
`;

export const Content = styled.div`
  width: 95vw;
  height: 100%;
  margin: auto;
  padding: 2.5rem 1.6rem;
  border-radius: 0.5rem;

  h4 {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;
    color: #fff;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20rem;
      height: 0.1rem;
      background: ${({ theme }) => theme.colors.titlePage};
      z-index: 0;
    }
  }
  .brand {
    ${Logo} {
      width: 20rem;
      margin: 2rem auto 1rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 1.8rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 3.2rem;
      color: #fff;
      ${PhoneIcon}, ${WhatsappIcon} {
        width: 1.4rem;
        margin-right: 1rem;
        color: #fff;
      }
    }
    .address,
    .links,
    .contact,
    .partner {
      margin: 2rem 0;
    }
    .address {
      display: flex;
      flex-direction: column;
    }
    .links {
      ul {
        list-style: none;
        li:last-child {
          padding: 0 0 0 0;
        }
        li {
          padding: 0 0 1.6rem 0;
          a {
            color: #fff;
            text-decoration: none;
            font-size: 1.6rem;
          }
        }
      }
    }
    .partner {
      a {
        ${Partner} {
          cursor: pointer;
          width: 11.5rem;
          border-radius: 0.4rem;
        }
      }
    }
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    flex-direction: row;
    h4 {
      &::before {
        width: 12rem;
      }
    }
    .address,
    .links,
    .contact,
    .partner {
      width: auto;
    }
    .info {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
`;
