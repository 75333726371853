import styled, { css } from "styled-components";
import { InstagramAlt, FacebookSquare } from "styled-icons/boxicons-logos";
import { Newsletter } from "styled-icons/entypo";
import media from "styled-media-query";

export const Container = styled.header`
  position: fixed;
  transition: 0.4s;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  transition: all 1s;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.12), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.24);
  ${(props) =>
    props.scroll > 100
      ? css`
          background: linear-gradient(
            241deg,
            ${({ theme }) => theme.colors.primary} 0%,
            ${({ theme }) => theme.colors.secondary} 100%
          );
        `
      : css`
          background: linear-gradient(
            241deg,
            ${({ theme }) => theme.colors.bgHeader} 0%,
            ${({ theme }) => theme.colors.bgHeader} 100%
          );
        `}

  ${media.greaterThan("large")` 
    padding: 0.8rem 1.6rem;
  `}
`;
export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 95vw;
  ${media.greaterThan("large")` 
  min-width: 1170px;
  padding: 0 1rem;
  `}
`;

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const NavItems = styled.div`
  display: block;
`;
export const NavSocial = styled.div`
  display: none;
  ${media.greaterThan("large")`
  display: block;
  width: auto;
  ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    li {
      padding: 0.3rem 0.7rem;
      a {
        text-decoration: none;
        ${(props) =>
          props.scroll > 100
            ? css`
                color: ${({ theme }) => theme.colors.body};
              `
            : css`
                color: ${({ theme }) => theme.colors.body};
              `}
      }
    }
  }
`}
`;

export const InstagramIcon = styled(InstagramAlt)`
  width: 2rem;
  height: 2rem;
`;
export const NewsletterIcon = styled(Newsletter)`
  width: 2rem;
  height: 2rem;
`;
export const FacebookIcon = styled(FacebookSquare)`
  width: 2rem;
  height: 2rem;
`;
