import React from "react";

import { Link, useStaticQuery, graphql } from "gatsby";

import { Container, Content, Logo, Partner, WhatsappIcon, PhoneIcon } from "./styles";

function Footer() {
  const { logo, bioaccus } = useStaticQuery(graphql`
    fragment typeImg on File {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    query {
      logo: file(relativePath: { eq: "common/logo2_branco.png" }) {
        ...typeImg
      }
      bioaccus: file(relativePath: { eq: "partners/logo-Bioaccus.png" }) {
        ...typeImg
      }
    }
  `);

  return (
    <Container>
      <Content>
        <div className="info">
          <div className="address">
            <h4>ENDEREÇO</h4>
            <p>
              Rua Simão Álvares, 941
              <br />
              Pinheiros - São Paulo-SP
            </p>
          </div>
          <div className="contact">
            <h4>Telefone</h4>
            <p>
              <PhoneIcon /> 11 3813-5080
              <br />
              <PhoneIcon /> 11 3815-5066
              <br />
              <WhatsappIcon /> 11 98228-8192
            </p>
          </div>
          <nav className="links">
            <h4>Links</h4>
            <ul>
              <li>
                <Link to="/sobre">Sobre</Link>
              </li>
              <li>
                <Link to="/telemedicina">Telemedicina</Link>
              </li>
              <li>
                <Link to="/contato">Contato</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="brand">
          <Logo fluid={logo.childImageSharp.fluid} />
        </div>
      </Content>
    </Container>
  );
}

export default Footer;
